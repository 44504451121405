import { useParams } from 'react-router-dom'
import { Box } from '@mantine/core'
import { ProgramLayout } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'

import { CampaignOverview } from '../../components/partials/dashboard/Overview'
import {
  useCampaign,
  useIntentReports,
  useMCOstats,
  useOpportunityStats,
  usePipelineStatus,
} from '../../hooks'

type Props = { campaignId: number }
export const ProgramMetricsPage = (props: Props) => {
  const { data: campaign } = useCampaign(props.campaignId)

  const dealStats = useOpportunityStats(props.campaignId)
  const mcoStats = useMCOstats(props.campaignId)
  const pipelineStats = usePipelineStatus(props.campaignId)
  const intentReportStats = useIntentReports(props.campaignId)

  return (
    <ProgramLayout
      status={
        mcoStats.isFetching ||
        dealStats.isFetching ||
        pipelineStats.isFetching ||
        intentReportStats.isFetching
          ? 'loading'
          : 'ready'
      }
      pageId='programs'
      fluid={true}
    >
      <Box>
        <CampaignOverview
          campaign={campaign}
          metrics={{
            ...dealStats.data,
            ...mcoStats.data,
            ...pipelineStats.data,
            ...intentReportStats.data,
          }}
        />
      </Box>
    </ProgramLayout>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)

  return <ProgramMetricsPage campaignId={campaignId} />
}

export const ProgramMetricsRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
