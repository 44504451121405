import * as React from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card } from '@mantine/core'
import { useCheckRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { ClientSwitcher, PageTitle, useNavLayout } from '@ospace/layout'
import { updateClientRequestSchema } from '@ospace/schemas'
import { ErrorBoundary } from '@ospace/shared'

import { ClientForm } from '../components/ClientForm'
import { useUpdateClientMutation } from '../hooks'

type Props = { clientId: number }
export const EditClientPage = (props: Props) => {
  const { client, setLayoutConfig } = useNavLayout()

  useEffect(() => {
    setLayoutConfig({ pageId: 'edit-client', pageTitle: 'Edit' })
  }, [setLayoutConfig])
  const navigate = useNavigate()
  const mutation = useUpdateClientMutation({ clientId: client.id })

  return (
    <>
      <PageTitle>Edit Client</PageTitle>
      <ClientSwitcher client={client} />
      <Card>
        <ClientForm
          clientId={client.id}
          initialValues={{
            ...client,
            logoFile: null as null | File,
            teamMembers: client.teamMembers || [],
          }}
          isSubmitting={mutation.isLoading}
          onSubmit={async (values: any) => {
            await mutation.mutateAsync({
              ...values,
              id: client?.id,
            })
            navigate(`/client/view/${client?.id}`)
          }}
          validationSchema={updateClientRequestSchema}
        />
      </Card>
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager])
  const params: any = useParams()
  const clientId = Number(params?.clientId)
  return <EditClientPage clientId={clientId} />
}

export const EditClientPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
