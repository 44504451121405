import React from 'react'
import { Box, Select } from '@mantine/core'
import { ProgramLayout } from '@ospace/layout'
import { VendorSalesPersonFilter } from '@ospace/shared'
import { ALL } from '@ospace/shared/components/VendorSalesPersonFilter'

import { Notes } from './Notes'

export const FilteredNotes = ({
  isFetching,
  data,
  label,
}: {
  isFetching: boolean
  label: string
  data: {
    notes: any[]
    vendorSalesPerson: {
      enabled: boolean
      data: any
    }
  }
}) => {
  const { SelectFilter, filteredData } = VendorSalesPersonFilter({
    data: data?.notes || [],
    vendorSalesPerson: data?.vendorSalesPerson?.data || [],
    filterKeyPath: 'company.id',
  })
  const [statusFilter, setStatusFilter] = React.useState<string>(ALL)
  const status = Object.keys(
    data?.notes.reduce((acc, note) => {
      return {
        ...acc,
        [note.deal.status]: 1,
      }
    }, {}) || {}
  ).sort((a, b) => a.localeCompare(b))

  return (
    <ProgramLayout status={isFetching ? 'loading' : 'ready'} pageId='programs' fluid={false}>
      {data && (
        <Box mt='xl'>
          <div className={`card`}>
            <div className='card-header border-0'>
              <h3
                className='card-title align-items-start flex-column'
                style={{
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '100%',
                    paddingTop: '20px',
                  }}
                >
                  <span className='card-label fw-bolder fs-3'>{label}</span>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      flexDirection: 'row',
                      width: '50%',
                    }}
                  >
                    <Select
                      data={[
                        {
                          label: ALL,
                          value: ALL,
                        },
                      ].concat(status.map((s) => ({ label: s, value: s })))}
                      value={statusFilter}
                      onChange={(s: string) => {
                        setStatusFilter(s)
                      }}
                      label={`Status`}
                    />
                    <div
                      style={{
                        paddingLeft: '20px',
                      }}
                    >
                      {data?.vendorSalesPerson.enabled && { ...SelectFilter }}
                    </div>
                  </div>
                </div>
              </h3>
            </div>
            <div className={`card-body`} style={{ overflow: 'scroll' }}>
              <Notes
                notes={filteredData?.filter((d) =>
                  statusFilter === ALL ? true : d.deal.status === statusFilter
                )}
              />
            </div>
          </div>
        </Box>
      )}
    </ProgramLayout>
  )
}
