import { Campaign, CreateCampaignRequest } from '@ospace/schemas'
import { API } from '@ospace/shared'

const getCampaigns = async () => {
  const campaigns = await API.get('client', `/campaigns`, {})
  return campaigns as Campaign[]
}

const getCampaign = async (id: number) => {
  const campaign = await API.get('client', `/campaigns/${id}`, {})
  return campaign as Campaign
}

const createCampaigns = async (campaign: CreateCampaignRequest) => {
  return API.post('client', `/campaigns`, {
    body: campaign,
  })
}

const editCampaign = async (campaign: CreateCampaignRequest, id: number) => {
  return API.put('client', `/campaigns/${id}`, {
    body: campaign,
  })
}

const deleteCampaign = async (id: number) => {
  return API.del('client', `/campaigns/${id}`, {})
}

const findCampaignsByClient = async (clientId: number): Promise<Campaign[]> => {
  const campaigns = await API.get('client', `/clients/${clientId}/campaigns`, {})
  return campaigns as Campaign[]
}

const getDashboards = async (campaignId: number) => {
  return await API.get('client', `/dashboards/campaigns/${campaignId}`, {})
}

const getDealPipelineStatus = async (campaignId: number) => {
  return (await API.get('client', `/deals/campaigns/${campaignId}/pipeline-status`, {})) as {
    qualified: { value: number; tooltip: '' }
    sql: { value: number; tooltip: '' }
    mql: { value: number; tooltip: '' }
    forecastARR: { value: number; tooltip: '' }
    forecastLTV: { value: number; tooltip: '' }
    currentARR: { value: number; tooltip: '' }
  }
}
const getDeals = async (campaignId: number) => {
  return await API.get('client', `/deals/campaigns/${campaignId}`, {})
}

const getContactsAndNotes = async (dealId: number, campaignId: number) => {
  return await API.get('client', `/deals/${dealId}/campaigns/${campaignId}/notes-contacts`, {})
}

const postDealViewedDate = async (dealId: number, campaignId: number) => {
  return await API.post('client', `/deals/campaigns/${campaignId}/viewed/${dealId}`, {})
}

const getOpportunitiesStats = async (campaignId: number) => {
  return await API.get('client', `/deals/campaigns/${campaignId}/opportunity-stats`, {})
}

const getMCOStats = async (campaignId: number) => {
  return await API.get('client', `/deals/campaigns/${campaignId}/mco-stats`, {})
}

const getIntentReports = async (campaignId: number) => {
  return await API.get('client', `/deals/campaigns/${campaignId}/intent-reports`, {})
}

export const CampaignService = {
  getCampaigns,
  getCampaign,
  createCampaigns,
  editCampaign,
  deleteCampaign,
  findCampaignsByClient,
  getDashboards,
  getDeals,
  getContactsAndNotes,
  postDealViewedDate,
  getOpportunitiesStats,
  getMCOStats,
  getDealPipelineStatus,
  getIntentReports,
}
