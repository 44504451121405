import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Space } from '@mantine/core'
import { useCheckRole, useUserRole } from '@ospace/auth'
import { isClientsDistributionPipelineAvailableToUserRole, userTypes } from '@ospace/core-auth'
import { useOpportunities } from '@ospace/distribution-pipeline/hooks'
import { SelectedPage, useNavLayout } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'
import { DashboardLoader } from '@ospace/shared/components/DashboardLoader'

import { useDataDogEmailTracker } from '../../datadog'
import { OpportunitiesTable } from '../components/OpportunitiesTable'
import { PipelineChart } from '../components/PipelineChart'
import { PipelineRatio } from '../components/PipelineRatio'
import { ProbabilityChart } from '../components/ProbabilityChart'
export const DistributionPipelinePage = ({ clientId }: { clientId: number }) => {
  const { client, duration, setLayoutConfig } = useNavLayout()
  const userRole = useUserRole()
  const navigate = useNavigate()

  const {
    isLoading,
    data: opportunities,
    progress,
    total,
  } = useOpportunities({ clientId, enabled: !!client?.distributionPipelineVendorId })

  useEffect(() => {
    setLayoutConfig({ pageId: 'pipeline', pageTitle: 'Pipeline' })
  }, [setLayoutConfig])

  useDataDogEmailTracker()

  const dataProps = {
    currency: client.currency || 'USD',
    duration,
    opportunities: opportunities || [],
    showNotes: client?.showNotes || false,
  }

  if (!isClientsDistributionPipelineAvailableToUserRole({ userRole, client })) {
    navigate('/error/403')
  }

  return (
    <>
      <SelectedPage>Pipeline</SelectedPage>
      {isLoading ? (
        <DashboardLoader progress={progress} total={total} />
      ) : (
        <>
          <PipelineChart {...dataProps} />
          <Space h='md' />
          <ProbabilityChart {...dataProps} />
          <Space h='md' />
          <PipelineRatio {...dataProps} />
          <Space h='md' />
          <OpportunitiesTable {...dataProps} />
        </>
      )}
    </>
  )
}

const Controller = () => {
  useCheckRole([
    userTypes.Admin,
    userTypes.ClientManager,
    userTypes.ClientViewer,
    userTypes.ClientUser,
    userTypes.InternalViewer,
    userTypes.CampaignManager,
  ])

  const params = useParams()
  const clientId = Number(params?.clientId)
  return <DistributionPipelinePage clientId={clientId} />
}

export const DistributionPipelinePageHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)

export default DistributionPipelinePageHandler
