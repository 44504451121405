import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, createStyles, Flex, Group, Menu, rem } from '@mantine/core'
import { useUserRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { Campaign } from '@ospace/schemas'
import { IconCaretDownFilled } from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  navButtons: {
    backgroundColor: 'white',
    color: 'black',
    paddingBottom: 10,
    fontSize: theme.fontSizes.md,
    fontWeight: 10,
    borderRadius: 0,
    '&:hover': {
      borderBottom: `${rem(2)} solid #00acee`,
      backgroundColor: 'transparent',
    },
  },
  activeNavButton: {
    borderBottom: `${rem(2)} solid #00acee`,
    backgroundColor: 'transparent',
  },
  menu: {
    fontSize: theme.fontSizes.md,
  },
}))

export default function ProgramNavigation({ campaign }: { campaign: Campaign }) {
  const role = useUserRole()
  const { classes } = useStyles()
  const location = useLocation() // Get the current location

  // Helper function to check if a link is active
  const isActive = (path: string) => location.pathname === path

  return (
    <div
      style={{
        backgroundColor: 'white',
        paddingLeft: 17,
        paddingTop: 10,
        borderBottom: '0.125rem solid #e9ecef',
      }}
    >
      <Flex
        align='center'
        gap={'lg'}
        justify={'space-between'}
        style={{
          paddingRight: '2rem',
        }}
      >
        <Group>
          <Button
            className={`${classes.navButtons} ${isActive(`/campaign/view/${campaign.id}`) ? classes.activeNavButton : ''}`}
            component={Link}
            to={`/campaign/view/${campaign.id}`}
          >
            Overview
          </Button>
          <Button
            className={`${classes.navButtons} ${isActive(`/program/view/${campaign.id}/program-metrics`) ? classes.activeNavButton : ''}`}
            component={Link}
            to={`/program/view/${campaign.id}/program-metrics`}
          >
            Program Metrics
          </Button>
          <Menu shadow='md' offset={0}>
            <Menu.Target>
              <Button className={classes.navButtons}>
                <>
                  Data and Intelligence
                  <IconCaretDownFilled
                    size={rem(13)}
                    style={{
                      marginLeft: 2,
                    }}
                  />
                </>
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                className={`${classes.menu} ${isActive(`/campaign/${campaign.id}/contact`) ? classes.activeNavButton : ''}`}
                component={Link}
                to={`/campaign/${campaign.id}/contact`}
              >
                Contacts
              </Menu.Item>
              <Menu.Item
                className={`${classes.menu} ${isActive(`/campaign/${campaign.id}/company`) ? classes.activeNavButton : ''}`}
                component={Link}
                to={`/campaign/${campaign.id}/company`}
              >
                Accounts
              </Menu.Item>
              <Menu.Divider />
              <Menu.Label className={classes.menu}>Analytics Dashboards</Menu.Label>
              {campaign?.objective?.isIntentsEnabled && (
                <Menu.Item
                  className={`${classes.menu} ${isActive(`/campaign/data-intel/${campaign.id}/dashboard/intent`) ? classes.activeNavButton : ''}`}
                  component={Link}
                  to={`/campaign/data-intel/${campaign.id}/dashboard/intent`}
                >
                  Intent data
                </Menu.Item>
              )}
              {campaign?.objective?.isContactsDiscoveredEnabled && (
                <Menu.Item
                  className={`${classes.menu} ${isActive(`/campaign/data-intel/${campaign.id}/dashboard/contact`) ? classes.activeNavButton : ''}`}
                  component={Link}
                  to={`/campaign/data-intel/${campaign.id}/dashboard/contact`}
                >
                  Contact data
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
          <Button
            className={`${classes.navButtons} ${isActive(`/campaign/${campaign.id}/files`) ? classes.activeNavButton : ''}`}
            component={Link}
            to={`/campaign/${campaign.id}/files`}
          >
            Files
          </Button>
          <Button
            className={`${classes.navButtons} ${isActive(`/campaign/${campaign.id}/account-insights`) ? classes.activeNavButton : ''}`}
            component={Link}
            to={`/campaign/${campaign.id}/account-insights`}
          >
            Account Insights
          </Button>
        </Group>
        <Group>
          {(role === userTypes.Admin ||
            role === userTypes.ClientManager ||
            role === userTypes.CampaignManager) && (
            <Button
              component={Link}
              to={`/campaign/${campaign.id}/objective/create`}
              size='xs'
              right={'true'}
              mr={'md'}
              style={{
                marginTop: -10,
              }}
            >
              Update Campaign Performance
            </Button>
          )}
        </Group>
      </Flex>
    </div>
  )
}
