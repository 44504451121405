import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect } from 'react'

const SplashScreenContext = createContext<Dispatch<SetStateAction<number>> | undefined>(undefined)

export const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

export const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

export const SplashScreen: FC<{ visible?: boolean }> = ({ visible = true }) => {
  enableSplashScreen()
  setTimeout(() => {
    disableSplashScreen()
  }, 1500)
  // Everything are ready - remove splashscreen
  const setCount = useContext(SplashScreenContext)

  useEffect(() => {
    if (!visible) {
      return
    }

    if (setCount) {
      setCount((prev) => {
        return prev + 1
      })
    }

    return () => {
      if (setCount) {
        setCount((prev) => {
          return prev - 1
        })
      }
    }
  }, [setCount, visible])

  return null
}
