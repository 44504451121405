import * as React from 'react'
import { Navigate } from 'react-router-dom'
import { Center, Loader } from '@mantine/core'
import { useUserRole } from '@ospace/auth'
import { useClients } from '@ospace/client'
import { userTypes } from '@ospace/core-auth'
import { ClientPageLayout, PageTitle, SelectedPage } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'
import { useUser } from '@ospace/user'

import { CampaignCards } from '../components/CampaignCards'
import { getUserNavigationCategory } from '../utils'

export const CampaignHomePage = (props: { role: userTypes }) => {
  const user = useUser()
  const client = useClients({
    nextPage: 0,
    pageLimit: 1,
    enabled: !!user.data,
  })

  return client.status === 'success' && user.status === 'success' ? (
    <ClientPageLayout
      status='ready'
      user={user.data}
      role={props.role}
      client={client.data?.clients[0] || user?.data?.clients[0]}
      subHeader={<PageTitle />}
      pageId='programs'
      pageTitle='Home'
    >
      <>
        <PageTitle>Home</PageTitle>
        <SelectedPage>Home</SelectedPage>
        <CampaignCards />
      </>
    </ClientPageLayout>
  ) : (
    <Center maw='100vh' h='100vh' mx='auto'>
      <Loader variant='bars' />
    </Center>
  )
}
const Controller = () => {
  const { data: user, status } = useUser()
  const client = useClients({
    nextPage: 0,
    pageLimit: 1,
    enabled: !!user,
  })

  const role = useUserRole()

  if (status === 'loading' || client.status === 'loading') {
    return (
      <Center maw='100vh' h='100vh' mx='auto'>
        <Loader variant='bars' />
      </Center>
    )
  }
  if (status !== 'success') {
    return <Navigate to='/login' />
  }

  if (!user.email) {
    return (
      <Center maw='100vh' h='100vh' mx='auto'>
        <Loader variant='bars' />
      </Center>
    )
  }
  try {
    const clientId = (client.data?.clients[0] || user?.clients[0])?.id
    const navigationCategory = getUserNavigationCategory(user, role)

    switch (navigationCategory) {
      case 'external-multilpe-clients':
        return <Navigate to='/clients' />
      case 'external-single-client':
        if (!clientId) {
          return <Navigate to='/' />
        }
        return <Navigate to={`/client/${clientId}/dashboard`} replace />
      case 'internal':
        return <Navigate to='/clients' />
      case 'campaign':
        return <CampaignHomePage role={role} />
      default:
        assertUnreachable(navigationCategory)
    }
  } catch (error) {
    console.error(error)
    return <Navigate to='/clients' />
  }
}

export const HomePageRouteHandler = () => {
  return (
    <ErrorBoundary>
      <Controller />
    </ErrorBoundary>
  )
}

function assertUnreachable(_: never): never {
  throw new Error('unreachable')
}
