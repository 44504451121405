import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useUserRole } from '@ospace/auth'
import { ClientHeader } from '@ospace/client/components/ClientHeader'
import { ClientNav } from '@ospace/client/components/ClientNav'
import { ClientSwitcher, PageLink, PageTitle } from '@ospace/layout'
import { SelectedPage, useNavLayout } from '@ospace/layout'

import { Campaigns } from '../components/Campaigns'
import { NewCampaignButton } from '../components/NewCampaignButton'
import { useClientCampaign } from '../hooks'

const ClientCampaignsPage: React.FC = () => {
  const userRole = useUserRole()

  const { client, setLayoutConfig } = useNavLayout()
  const campaigns = useClientCampaign(client.id)

  useEffect(() => {
    setLayoutConfig({ pageId: 'programs', pageTitle: 'Programs' })
  }, [setLayoutConfig])

  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isActive: false,
    },
    {
      title: 'Clients',
      path: '/',
      isActive: true,
    },
  ]

  return (
    <>
      {campaigns.isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : campaigns.status === 'error' ? (
        <span>Error: {(campaigns.error as any).message}</span>
      ) : (
        <>
          <ClientSwitcher client={client} />
          <SelectedPage>Programs</SelectedPage>
          <PageTitle breadcrumbs={breadCrumbs}>Overview</PageTitle>
          {campaigns.isSuccess && (
            <>
              <ClientHeader client={client} userRole={userRole} campaigns={campaigns.data} />
              <ClientNav client={client} userRole={userRole} selectedTab='campaigns'>
                <NewCampaignButton client={client} userRole={userRole} />
              </ClientNav>
            </>
          )}
          {campaigns.isSuccess && <Campaigns campaigns={campaigns.data} />}
        </>
      )}
    </>
  )
}

export { ClientCampaignsPage }
