import React from 'react'
import { Card, Group, Progress, Stack, Text, Title } from '@mantine/core'

type Props = {
  totalGoal?: number
  className?: string
  metricInfo: {
    title: string
    subTitle?: string
    metrics: Array<{
      title: string
      target?: number
      value: number
      enabled: boolean
      link?: string
    }>
  }
  lastUpdate?: any
}

const MetricSummary: React.FC<Props> = ({ className, metricInfo, lastUpdate }) => {
  const enabledMetrics = metricInfo.metrics.filter(({ enabled }) => enabled)

  return (
    <Card shadow='sm' padding='xl' radius='md' className={className}>
      {/* Header */}
      <Stack spacing='md' mb='xl'>
        <Title order={3}>{metricInfo.title}</Title>
        {metricInfo.subTitle && (
          <Text size='sm' color='dimmed'>
            {metricInfo.subTitle}
          </Text>
        )}
      </Stack>

      {/* Metrics Overview */}
      <Group
        spacing='xl'
        mb='xl'
        position='apart'
        align='center'
        style={{
          display: 'flex',
          justifyContent: enabledMetrics.length === 2 ? 'space-around' : 'space-between',
          gap: '5rem',
        }}
      >
        {enabledMetrics.map((metric, key) => (
          <Stack key={key} spacing='xs' align='center' style={{ minWidth: '150px' }}>
            <Text size='sm' color='dimmed' weight={500}>
              {metric?.title}
            </Text>
            <Text size='xl' weight={700}>
              {metric?.value}
            </Text>
          </Stack>
        ))}
      </Group>

      {/* Metrics List */}
      <Stack spacing='lg'>
        {metricInfo.metrics
          .filter(({ enabled }) => enabled)
          .map((metric, key) => (
            <Card key={key} withBorder padding='xl' radius='md'>
              <Stack spacing='md'>
                <Title order={4}>{metric.title} progress</Title>
                <Group position='apart' spacing='xl'>
                  <Text size={24} weight={700}>
                    {metric.target && metric.target > 0
                      ? `${Math.ceil((metric.value / metric.target) * 100)}%`
                      : ''}
                  </Text>
                  <Text size={24} weight={700} color='dark'>
                    {metric.target} Goal
                  </Text>
                </Group>
                <Progress
                  value={(metric.value / (metric.target || 1)) * 100}
                  size='lg'
                  radius='md'
                />
              </Stack>
            </Card>
          ))}
      </Stack>
    </Card>
  )
}

export { MetricSummary }
