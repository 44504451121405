import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  Center,
  createStyles,
  getStylesRef,
  Group,
  Image,
  Navbar,
  rem,
  Text,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from '@mantine/core'
import { User } from '@ospace/auth'
import {
  isClientsDistributionPipelineAvailableToUserRole,
  isClientsLeadsDashboardAvailableToUserRole,
  isClientTeamsAvailableToUserRole,
  userTypes,
} from '@ospace/core-auth'
import { isClientsSalesDashboardAvailableToUserRole } from '@ospace/core-auth'
import { getUserNavigationCategory } from '@ospace/home'
import { Client } from '@ospace/schemas'
import {
  IconChartArcs,
  IconChartBar,
  IconChartBubble,
  IconChartHistogram,
  IconChevronLeft,
  IconChevronRight,
  IconHome,
  IconSwitchHorizontal,
  IconUsersGroup,
} from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  sideBar: {
    padding: theme.spacing.md,
    borderRight: `${rem(2)} solid ${
      theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[2]
    }`,
  },

  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.md,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
    padding: `${theme.spacing.md} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,

      [`& .${getStylesRef('icon')}`]: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  },

  navIcon: {
    position: 'absolute',
    zIndex: 10,
    top: 20,
    right: -13,
    background: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[4],
    borderRadius: '50%',
    padding: 5,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.white,
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    marginRight: theme.spacing.md,
    padding: 3,
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      [`& .${getStylesRef('icon')}`]: {
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      },
    },
    fontWeight: 700,
  },

  linkDisabled: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
  },

  footer: {
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingTop: theme.spacing.md,
  },
}))

export type PageId =
  | 'dashboard'
  | 'leads'
  | 'sales'
  | 'pipeline'
  | 'analytics'
  | 'programs'
  | 'my-teams'

type SideNavbarProps = {
  opened: boolean
  setOpened: any
  client?: Client
  activePageId: PageId
  user?: User
  role: userTypes
}
export const SideNavbar = (props: SideNavbarProps) => {
  const { opened, setOpened, client, activePageId } = props
  const { classes, cx } = useStyles()
  const navigationCategory = props.user ? getUserNavigationCategory(props.user, props.role) : null

  const SideBarCollapseButton = (
    <UnstyledButton onClick={() => setOpened((o: any) => !o)}>
      {opened ? (
        <Tooltip label='Collapse' withArrow position='right'>
          <IconChevronLeft size={rem(30)} stroke={3} className={classes.navIcon} />
        </Tooltip>
      ) : (
        <Tooltip label='Expand' withArrow position='right'>
          <IconChevronRight size={rem(30)} stroke={3} className={classes.navIcon} />
        </Tooltip>
      )}
    </UnstyledButton>
  )

  const SideNavBarWidth = opened ? { base: 300, sm: 300, md: 300, lg: 300 } : { base: 100 }

  const Logo = opened ? (
    <Image src={'/media/logos/oSpace.svg'} width={200} height={40} />
  ) : (
    <Center w={100}>
      <Image src={'/media/logos/ospace-xs.svg'} width={40} height={40} />
    </Center>
  )

  return (
    <Navbar width={SideNavBarWidth} className={classes.sideBar}>
      {SideBarCollapseButton}
      <Navbar.Section grow>
        <Group className={classes.header} position='apart'>
          {Logo}
        </Group>
        <PageLink
          to={
            client && props.role !== userTypes.CampaignUser ? `/client/${client.id}/dashboard` : '/'
          }
          pageId='dashboard'
          activePageId={activePageId}
          icon={<IconHome />}
          label='Home'
          color='orange'
          sideBarOpened={opened}
          disabled={!client}
        />
        <PageLink
          to={client ? `/client/${client.id}/leads-dashboard` : ''}
          pageId='leads'
          activePageId={activePageId}
          icon={<IconChartHistogram />}
          label='Leads'
          color='green'
          disabled={
            !client ||
            !isClientsLeadsDashboardAvailableToUserRole({
              client: client as Client,
              userRole: props.role,
            })
          }
          sideBarOpened={opened}
        />
        <PageLink
          to={client ? `/client/${client.id}/distribution-pipeline` : ''}
          pageId='pipeline'
          activePageId={activePageId}
          icon={<IconChartBubble />}
          label='Pipeline'
          color='violet'
          disabled={
            !client ||
            !isClientsDistributionPipelineAvailableToUserRole({
              client: client,
              userRole: props.role,
            })
          }
          sideBarOpened={opened}
        />
        <PageLink
          to={client ? `/client/${client.id}/sales-dashboard` : ''}
          pageId='sales'
          activePageId={activePageId}
          icon={<IconChartBar />}
          label='Sales'
          color='grape'
          disabled={
            !client ||
            !isClientsSalesDashboardAvailableToUserRole({
              client: client,
              userRole: props.role,
            })
          }
          sideBarOpened={opened}
        />
        <PageLink
          to={client ? buildProgramsLink(props.role, client) : ''}
          pageId='programs'
          activePageId={activePageId}
          icon={<IconChartArcs />}
          label='Programs'
          color='cyan'
          sideBarOpened={opened}
        />
      </Navbar.Section>
      <Navbar.Section className={classes.footer}>
        {(navigationCategory === 'internal' ||
          navigationCategory === 'external-multilpe-clients') && (
          <Link to='/' className={cx(classes.link)}>
            <ThemeIcon variant='light' className={classes.linkIcon} size={'lg'}>
              <IconSwitchHorizontal stroke={1.5} />
            </ThemeIcon>
            {opened && <Text>Change Client</Text>}
          </Link>
        )}

        {client &&
          isClientTeamsAvailableToUserRole({
            userRole: props.role,
            client: client,
          }) && (
            <PageLink
              to={client ? `/client/${client?.id}/my-teams` : ``}
              pageId='my-teams'
              activePageId={activePageId}
              icon={<IconUsersGroup stroke={1.5} />}
              label='My Teams'
              color='cyan'
              sideBarOpened={opened}
            />
          )}
      </Navbar.Section>
    </Navbar>
  )
}

const buildProgramsLink = (role: userTypes, client: Client) => {
  if (role === userTypes.CampaignUser) {
    return '/'
  }
  return `/client/view/${client.id}`
}

type PageLinkProps = {
  to: string
  pageId: PageId
  activePageId: PageId
  icon: any
  label: string
  disabled?: boolean
  sideBarOpened?: boolean
  color: string
}

const PageLink = (props: PageLinkProps) => {
  const { classes, cx } = useStyles()
  if (props.disabled) {
    return (
      <span
        className={cx(classes.link, {
          [classes.linkActive]: props.pageId === props.activePageId,
          [classes.linkDisabled]: props.disabled,
        })}
        key={props.pageId}
        color={'dimmed'}
      >
        {props.sideBarOpened ? (
          <>
            <ThemeIcon
              color={props.disabled ? 'gray' : props.color}
              variant='light'
              className={classes.linkIcon}
              size={'lg'}
            >
              {props.icon}
            </ThemeIcon>

            <Text size='md'>{props.label}</Text>
          </>
        ) : (
          <Tooltip label={props.label} withArrow position='right'>
            <ThemeIcon color={'gray'} variant='light' className={classes.linkIcon} size={'lg'}>
              {props.icon}
            </ThemeIcon>
          </Tooltip>
        )}
      </span>
    )
  }
  return (
    <Link
      className={cx(classes.link, {
        [classes.linkActive]: props.pageId === props.activePageId,
        [classes.linkDisabled]: props.disabled,
      })}
      to={props.to}
      key={props.pageId}
      color={'dark'}
      role='link'
      aria-label={props.label}
    >
      {props.sideBarOpened ? (
        <>
          <ThemeIcon
            color={props.disabled ? 'gray' : props.color}
            variant='light'
            className={classes.linkIcon}
            size={'lg'}
          >
            {props.icon}
          </ThemeIcon>

          <Text size='md'>{props.label}</Text>
        </>
      ) : (
        <Tooltip label={props.label} withArrow position='right'>
          <ThemeIcon color={props.color} variant='light' className={classes.linkIcon} size={'lg'}>
            {props.icon}
          </ThemeIcon>
        </Tooltip>
      )}
    </Link>
  )
}
