import { useState } from 'react'
import { Badge, Center, createStyles, Grid, Paper, Stack, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { ColDef, CompanyLogo, NewTable, VendorSalesPersonFilter } from '@ospace/shared'
import dayjs from 'dayjs'

import { DealModal } from './DealModal'

type TableData = {
  dealCampaignId: number
  dealId: number
  dealData: any
  companyData: any
  contactData: any
  createdAt: string
  noteCreatedAt: string
  dealViewedAt: string
  noteBody: string
  newOpportunity: boolean
  newActivity: boolean
  conversationNotes?: string
}

type TransformedDeal = {
  campaignId: number
  dealId: number
  status: string
  domain: string
  company: string
  contact: string
  role: string
  newActivity: boolean
  newOpportunity: boolean
  noteBody: string
  createdAt: string
  dealViewedAt: string | null
  conversationNotes: string
}

enum DealLabel {
  TotalOpportunities = 'Total Opportunities',
  Meetings = 'Meetings',
  MeetingsBooked = 'Meetings Booked',
  HighTouchNurture = 'High Touch Nurture',
  NewOpportunities = 'New Opportunities',
  NewActivities = 'New Acitivites',
  Opportunities = 'Opportunities',
}

enum DealStatus {
  leadReview = 'a Lead – Review',
  leadRequested = 'a Lead – Requested',
  leadApproved = 'a Lead Approved',
  highTouchNurture = 'b High Touch Nurture',
}

const status = {
  'a Lead – Requested': 'HTN',
  'b High Touch Nurture': 'HTN',
  'a Lead – Review': 'Meeting Booked',
  'a Lead Approved': 'Meeting Complete',
}

const useStyles = createStyles(() => ({
  companyLogoText: {
    textWrap: 'wrap',
    lineHeight: '1.1',
  },
  roleText: {
    textWrap: 'balance',
  },
}))

const filterAndSortDeals: any = (data: TransformedDeal[], statuses: DealStatus[]) => {
  return data
    .filter((deal: TransformedDeal) => statuses.includes(deal.status as DealStatus))
    .sort((a: TransformedDeal, b: TransformedDeal) => {
      if (a.newOpportunity && !b.newOpportunity) return -1
      if (a.newActivity && !b.newActivity) return -1
      return 0
    })
}

const OpportunitiesTable = ({
  deals,
  vendorSalesPerson,
}: {
  deals: any
  vendorSalesPerson: any
}) => {
  const [opened, { open, close }] = useDisclosure(false)
  const [modalData, setModalData] = useState(deals)
  const { classes } = useStyles()
  const colDef: ColDef[] = [
    {
      key: 'status',
      label: 'Status',
      element: (row) => (
        <Badge color='gray' variant='filled'>
          {status[row.status as keyof typeof status]}
        </Badge>
      ),
      hideHeader: true,
    },
    {
      key: 'company-logo',
      label: 'Company',
      element: (row) => <CompanyLogo domain={row.domain} companyName={row.company} />,
    },
    {
      key: 'contact',
      label: 'Contact',
      element: (row) => {
        return (
          <Stack spacing={'xs'}>
            <Text>{row.contact}</Text>
            <Text className={classes.roleText}>{row.role}</Text>
          </Stack>
        )
      },
    },
    {
      key: 'createdAt',
      label: 'Date',
      element: (row) => dayjs(row.createdAt).format('DD/MM/YYYY'),
    },
    {
      key: 'note',
      label: 'Note',
      width: '400px',
      element: (row) => {
        const noteHtml = row.noteBody
        //extract text from html string
        const noteText = noteHtml.replace(/<[^>]+>/g, '')
        const tempNoteElement = document.createElement('div')
        tempNoteElement.innerHTML = noteText
        const formattedNoteText = tempNoteElement.textContent || tempNoteElement.innerText || ''

        const conversationNoteHtml = row.conversationNotes
        //extract text from html string
        const conversationNoteText = conversationNoteHtml.replace(/<[^>]+>/g, '')
        const conversationTempElement = document.createElement('div')
        conversationTempElement.innerHTML = conversationNoteText
        const formattedConversationNoteText =
          conversationTempElement.textContent || conversationTempElement.innerText || ''

        return (
          <Text truncate='end' lineClamp={4} style={{ whiteSpace: 'normal' }}>
            {formattedConversationNoteText} {formattedNoteText}
          </Text>
        )
      },
    },
    {
      key: 'newOpportunity',
      label: 'New Opportunity',
      element: (row) => (
        <>
          {row.newOpportunity ? (
            <Badge color='red' variant='filled'>
              New Opportunity
            </Badge>
          ) : row.newActivity ? (
            <Badge color='green' variant='filled'>
              New Activity
            </Badge>
          ) : null}
        </>
      ),
    },
  ]

  const data = deals?.data?.map((deal: TableData) => {
    return {
      campaignId: deal.dealCampaignId,
      companyId: deal.companyData?.id,
      dealId: deal.dealId,
      status: deal.dealData?.properties.ospace_status,
      companyBackground: deal.dealData?.properties?.company_background || '',
      conversionNotes: deal.dealData?.properties?.conversation_notes || '',
      domain: deal.companyData?.properties?.domain || '',
      company: deal.companyData?.properties?.name || '-',
      contact:
        deal.dealData?.properties.contact_name ||
        `${deal.contactData?.properties?.firstname} ${deal.contactData?.properties?.lastname}` ||
        '-',
      role: deal.contactData?.properties?.jobtitle || '-',
      newActivity: deal.noteCreatedAt > deal.dealViewedAt ? true : false, //Randonly set true or false
      newOpportunity: deal.dealViewedAt === null,
      noteBody: deal.noteBody || '-',
      createdAt: deal.dealData.createdAt,
      dealViewedAt: deal?.dealViewedAt,
      conversationNotes: deal.dealData?.properties?.conversation_notes || '',
    }
  })

  const meetingCompleteData = filterAndSortDeals(data, [DealStatus.leadApproved])
  const meetingBookedData = filterAndSortDeals(data, [DealStatus.leadReview])
  const htnData = filterAndSortDeals(data, [DealStatus.leadRequested, DealStatus.highTouchNurture])

  const finalData = [...meetingCompleteData, ...meetingBookedData, ...htnData]
  const [filteredData, setFilteredData] = useState(finalData)
  const { SelectFilter, filteredData: vendorFilteredData } = VendorSalesPersonFilter({
    data: filteredData || [],
    vendorSalesPerson: vendorSalesPerson.data,
    filterKeyPath: 'companyId',
  })
  const Filter = (data: TableData[], setData: any, setCurrentPage: any) => {
    const [selected, setSelected] = useState(DealLabel.TotalOpportunities as string)

    const onClickHandler = (label: string) => {
      setSelected(label)
      setCurrentPage(1)

      switch (label) {
        case DealLabel.TotalOpportunities:
          return setFilteredData(finalData)
        case DealLabel.NewOpportunities:
          return setFilteredData(finalData.filter((deal: TableData) => deal.newOpportunity))
        case DealLabel.NewActivities:
          return setFilteredData(finalData.filter((deal: TableData) => deal.newActivity))
        case DealLabel.Meetings:
          return setFilteredData(
            finalData.filter((deal: any) => {
              return (
                deal.status === DealStatus.leadReview || deal.status === DealStatus.leadApproved
              )
            })
          )
        case DealLabel.HighTouchNurture:
          return setFilteredData(
            finalData.filter((deal: any) => {
              return (
                deal.status === DealStatus.leadRequested ||
                deal.status === DealStatus.highTouchNurture
              )
            })
          )
        default:
          return setFilteredData(finalData)
      }
    }
    return (
      <Center m={'md'}>
        <Grid>
          {[
            {
              label: DealLabel.TotalOpportunities,
              value: finalData?.length,
            },
            {
              label: DealLabel.Meetings,
              value:
                finalData?.filter((deal: any) => deal.status === DealStatus.leadReview).length +
                finalData?.filter((deal: any) => deal.status === DealStatus.leadApproved).length,
            },
            {
              label: DealLabel.HighTouchNurture,
              value:
                finalData?.filter((deal: any) => deal.status === DealStatus.leadRequested).length +
                finalData?.filter((deal: any) => deal.status === DealStatus.highTouchNurture)
                  .length,
            },
            {
              label: DealLabel.NewOpportunities,
              value: finalData?.filter((deal: any) => deal.newOpportunity).length,
            },
            {
              label: DealLabel.NewActivities,
              value: finalData?.filter((deal: any) => deal.newActivity).length,
            },
          ].map(({ label, value }) => (
            <Stack
              key={label}
              style={{
                cursor: 'pointer',
                borderRight: label !== DealLabel.NewActivities ? '1px solid gray' : '',
                backgroundColor: selected === label ? '#e8eef3' : '',
              }}
              spacing={'xs'}
              px={'lg'}
              my={'lg'}
              onClick={() => onClickHandler(label)}
            >
              <Text c='dimmed' fw={500} fz='14px' tt={'uppercase'} mt={10}>
                {label}
              </Text>
              <Center fw={700} fz='24px' c='gray.7' mb={5}>
                {value}
              </Center>
            </Stack>
          ))}
        </Grid>
      </Center>
    )
  }

  return data?.length > 0 ? (
    <Paper withBorder p='xl' radius='md' shadow='xs'>
      <NewTable
        tableData={vendorFilteredData}
        colDef={colDef}
        hideHeader={true}
        filter={Filter}
        title='Opportunities'
        tableProps={{
          itemsPerPage: 6,
          verticalSpacing: 'lg',
          horizontalSpacing: 0,
        }}
        modal={{
          component: <DealModal opened={opened} onClose={close} data={modalData} />,
          setModalData,
          open,
        }}
        vendorSalesPersonFilter={vendorSalesPerson.enabled ? SelectFilter : <></>}
      />
    </Paper>
  ) : (
    <>
      <Title>No Opportunities</Title>
    </>
  )
}

export default OpportunitiesTable
