import { OutreachWidget } from './OutreachWidget'

interface Props {
  htn: {
    value: number
    delta: string
  }
  ltn: {
    value: number
    delta: string
  }
  upcomingMeetings: {
    value: number
    delta: string
  }
  requestedCallbacks: {
    value: number
    delta: string
  }
}

const titleMap = {
  htn: 'HTN',
  ltn: 'LTN',
  upcomingMeetings: 'Upcoming Meetings',
  requestedCallbacks: 'Requested Callbacks',
} as Record<string, string>
export function TeleSalesWidget(props: Props) {
  const data = Object.entries(props).map(([key, item]) => {
    return { ...item, title: titleMap[key] }
  })

  return (
    <OutreachWidget
      data={data}
      title='TELE SALES OUTREACH'
      rightTitle='OPPORTUNITIES'
      leftTitle='meetings'
    />
  )
}
