import { FC, useEffect } from 'react'
import { useNavLayout } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'

import { CampaignForm } from '../components/create-campaign/CampaignForm'

const CreateCampaignPage: FC = () => {
  const { setLayoutConfig } = useNavLayout()

  useEffect(() => {
    setLayoutConfig({ pageId: 'create-campaign', pageTitle: 'Create Campaign' })
  }, [setLayoutConfig])

  return (
    <>
      <CampaignForm type='create' />
    </>
  )
}

export { CreateCampaignPage }

export const Controller = () => {
  return <CreateCampaignPage />
}

export const CreateCampaignPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
