import { User } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { UserNavigationCategory } from '@ospace/schemas'

/**
internal users work at nextgen. They have multiple clients. and have a Dashboard link for the client homepage.
 
campaign users only see campaigns. They can have mutiple campaigns for multiple clients. They don't get access to any other functionality. They can be internal or external.
 
external-single-client are external users which only have one client. They don't need any of the multi-client functionality. They have a home link for their client's dashboard.
 
external-multiple-clients are external users with multiple clients. They need to be able to navigate between clients, so they have a Dashboard link in the side nave for their currently selected client. Their home page is the multi client selector.
*/

export const getUserNavigationCategory = (user: User, role: number): UserNavigationCategory => {
  // TODO: refactor this later
  if (user.userStatus === 'SSO VERIFIED') {
    return 'internal'
  }

  if (
    [
      userTypes.Admin,
      userTypes.ClientManager,
      userTypes.CampaignManager,
      userTypes.InternalViewer,
    ].includes(role)
  ) {
    return 'internal'
  }
  if ([userTypes.ClientViewer, userTypes.ClientUser].includes(role) && user?.clients.length > 1) {
    return 'external-multilpe-clients'
  }
  if ([userTypes.ClientViewer, userTypes.ClientUser].includes(role) && user?.clients.length === 1) {
    return 'external-single-client'
  }
  if ([userTypes.ClientViewer, userTypes.ClientUser].includes(role) && user?.clients.length === 0) {
    return 'external-multilpe-clients'
  }
  // This category exists to hide dashboard link for campaign users
  if ([userTypes.CampaignUser].includes(role)) {
    return 'campaign'
  }
  return 'campaign'
}
