import { Link, useParams } from 'react-router-dom'
import { Box, Center, Flex, Grid, Loader, Text } from '@mantine/core'
import { ProgramLayout } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'
import { useVendorSalesPerson } from '@ospace/shared/hooks/useVendorSalesPerson'

import {
  ContactsDiscoveryCompaniesCard,
  ContactsDiscoveryContactsCard,
} from '../../components/ContactsDiscoveryCard'
import { MultiChannelWidget, TeleSalesWidget } from '../../components/outreach-widget'
import OpportunitiesTable from '../../components/partials/dashboard/OpportunitiesTable'
import { PipelineStatus } from '../../components/PipelineStatus'
import { RevenueForecast } from '../../components/RevenueForecast'
import {
  useCampaign,
  useDeals,
  useMCOstats,
  useOpportunityStats,
  usePipelineStatus,
} from '../../hooks'

type Props = { campaignId: number }

export const NewOverviewPage = (props: Props) => {
  const { data: campaign } = useCampaign(props.campaignId)
  const deals = useDeals(props.campaignId)
  const { data: vendorSalesPerson, isFetching: vendorIsFetching } = useVendorSalesPerson(
    props.campaignId
  )
  const dealStats = useOpportunityStats(props.campaignId)
  const mcoStats = useMCOstats(props.campaignId)

  const { isLoading, data: pipelineStatusData } = usePipelineStatus(props.campaignId)
  const currency = campaign?.client?.currency || ''
  return (
    <ProgramLayout
      status={deals.isFetching || vendorIsFetching ? 'loading' : 'ready'}
      pageId='programs'
      fluid={true}
    >
      <Box mt='md'>
        <Flex gap='sm' align='stretch' pb='sm'>
          <Grid
            style={{
              width: '100%',
              margin: '0',
            }}
          >
            <Grid.Col span={6}>
              <Grid>
                <Grid.Col
                  span={6}
                  style={{
                    paddingLeft: 0,
                  }}
                >
                  <RevenueForecast
                    pipelineStatusData={pipelineStatusData}
                    isLoading={isLoading}
                    currency={currency}
                  />
                </Grid.Col>
                <Grid.Col span={6} h='100%'>
                  <PipelineStatus
                    pipelineStatusData={pipelineStatusData}
                    isLoading={isLoading}
                    currency={currency}
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col
              span={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingRight: 0,
              }}
            >
              <Flex
                gap='sm'
                align='stretch'
                style={{
                  flexBasis: '50%',
                }}
              >
                <div
                  style={{
                    flexBasis: '80%',
                    // paddingTop: 'sm',
                  }}
                >
                  {mcoStats.isFetching ? (
                    <Center h='100%' w='100%'>
                      <Loader />
                    </Center>
                  ) : (
                    <MultiChannelWidget
                      emailsSent={(mcoStats.data as any).total_emails_sent || 0}
                      openRate={`${(mcoStats.data as any).emails_open_rate || 0} %`}
                      requests={(mcoStats.data as any).total_linkedin_requests_sent || 0}
                      accepted={(mcoStats.data as any).total_linkedin_requests_accepted || 0}
                    />
                  )}
                </div>
                <div
                  style={{
                    flexBasis: '20%',
                  }}
                >
                  {dealStats.isFetching ? (
                    <Center h='100%' w='100%'>
                      <Loader />
                    </Center>
                  ) : (
                    <ContactsDiscoveryCompaniesCard
                      companies={dealStats.data.discovery['companies']}
                    />
                  )}
                </div>
              </Flex>
              <Flex
                gap='sm'
                align='stretch'
                style={{
                  flexBasis: '50%',
                }}
              >
                <div
                  style={{
                    flexBasis: '80%',
                    paddingTop: '0.75rem',
                  }}
                >
                  {dealStats.isFetching ? (
                    <Center h='100%' w='100%'>
                      <Loader />
                    </Center>
                  ) : (
                    <TeleSalesWidget
                      htn={{
                        value: dealStats.data?.teleSalesOutreach['HTN'],
                        delta: !!dealStats.data?.telseSalesOutreachThisWeek['HTN']
                          ? `+ ${dealStats.data?.telseSalesOutreachThisWeek['HTN']} this week`
                          : ``,
                      }}
                      ltn={{
                        value: dealStats.data?.teleSalesOutreach['LTN'],
                        delta: !!dealStats.data?.telseSalesOutreachThisWeek['LTN']
                          ? `+ ${dealStats.data?.telseSalesOutreachThisWeek['LTN']} this week`
                          : ``,
                      }}
                      upcomingMeetings={{
                        value: dealStats.data?.teleSalesOutreach['Upcoming Meetings'],
                        delta: !!dealStats.data?.telseSalesOutreachThisWeek['Upcoming Meetings']
                          ? `+ ${dealStats.data?.telseSalesOutreachThisWeek['Upcoming Meetings']} this week`
                          : ``,
                      }}
                      requestedCallbacks={{
                        value: dealStats.data?.teleSalesOutreach['Call Back'],
                        delta: !!dealStats.data?.telseSalesOutreachThisWeek['Call Back']
                          ? `+ ${dealStats.data?.telseSalesOutreachThisWeek['Call Back']} this week`
                          : ``,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    flexBasis: '20%',
                  }}
                >
                  {dealStats.isFetching ? (
                    <Center h='100%' w='100%'>
                      <Loader />
                    </Center>
                  ) : (
                    <ContactsDiscoveryContactsCard
                      contacts={dealStats.data.discovery['contacts']}
                      rateOfChange={
                        dealStats.data.discoveryThisWeek['contacts'] !== '0'
                          ? `+ ${dealStats.data.discoveryThisWeek['contacts']}`
                          : ``
                      }
                    />
                  )}
                </div>
              </Flex>
            </Grid.Col>
          </Grid>
        </Flex>
        {deals.data && deals.data.length > 0 ? (
          <OpportunitiesTable deals={deals} vendorSalesPerson={vendorSalesPerson} />
        ) : (
          <Loader />
        )}

        {deals.data && deals.data.length === 0 && (
          <Text c='grey'>
            If you are looking for the previous Program Metrics page,{' '}
            <Link to={`/program/view/${campaign?.id}/program-metrics`}>click here</Link>
          </Text>
        )}
      </Box>
    </ProgramLayout>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)

  return <NewOverviewPage campaignId={campaignId} />
}

export const NewOverviewPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
