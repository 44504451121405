import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Space } from '@mantine/core'
import { useUserRole } from '@ospace/auth'
import { useClientCampaign } from '@ospace/campaign'
import { dateFilter } from '@ospace/common'
import { isClientsLeadsDashboardAvailableToUserRole } from '@ospace/core-auth'
import { SelectedPage, useNavLayout } from '@ospace/layout'
import { Lead } from '@ospace/schemas/lead'
import { ErrorBoundary } from '@ospace/shared'
import { DashboardLoader } from '@ospace/shared/components/DashboardLoader'

import { useDataDogEmailTracker } from '../../datadog'
import { LeadsBarChart } from '../components/LeadsBarChart'
import { LeadsTable } from '../components/LeadsTable/LeadsTable'
import { LeadsWidgets } from '../components/LeadsWidgets/LeadsWidgets'
import { useLeads } from '../hooks/useLeadsDashBoard'

export const LeadsDashBoardPage = ({ clientId }: { clientId: number }) => {
  const { client, duration, setLayoutConfig } = useNavLayout()

  useEffect(() => {
    setLayoutConfig({ pageId: 'leads', pageTitle: 'Leads' })
  }, [setLayoutConfig])

  const userRole = useUserRole()
  const navigate = useNavigate()
  useDataDogEmailTracker()
  const { status: leadsStatus, data: leads, progress, total } = useLeads({ clientId })
  const campaignsResponse = useClientCampaign(clientId)

  const filteredLeads = (leads as any)?.filter((lead: any) =>
    dateFilter({
      date:
        lead.opportunityDate || lead.meetingBookedDate || lead.leadDate || lead.discoveryDate || '',
      duration,
      format: 'YYYY-MM-DDTHH:mm:ss',
    })
  ) as Lead[]

  const dataProps = {
    currency: client.currency || 'USD',
    duration: duration,
    leads: (leads || []) as Lead[],
    campaigns: campaignsResponse?.data || [],
  }

  if (!isClientsLeadsDashboardAvailableToUserRole({ userRole, client })) {
    navigate('/error/403')
  }

  return (
    <>
      <SelectedPage>Leads</SelectedPage>
      {leadsStatus !== 'success' ? (
        <DashboardLoader progress={progress} total={total} />
      ) : (
        <>
          <LeadsWidgets {...dataProps} />
          <Space h='md' />
          <LeadsBarChart {...dataProps} />
          <Space h='md' />
          <LeadsTable
            {...{
              ...dataProps,
              leads: filteredLeads,
            }}
          />
        </>
      )}
    </>
  )
}

const Controller = () => {
  const params = useParams()
  const clientId = Number(params?.clientId)
  return <LeadsDashBoardPage clientId={clientId} />
}

export const LeadsDashBoardPageHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
