import React, { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { MantineProvider, rem } from '@mantine/core'
import { AuthProvider } from '@ospace/auth'
import { disableSplashScreen, enableSplashScreen, NotificationProvider } from '@ospace/shared'

import { Routes } from '../routes/Routes'

type Props = {}

const App: React.FC<Props> = () => {
  useEffect(() => {
    // Disable the value of a focused number input changing when the mouse
    // wheel is used. This was reported as a bug in OP-537. It is default
    // browser behavior.  This solution came from
    // https://stackoverflow.com/a/38589039. It unfocuses the input when the
    // mouse wheel is moved.
    document.addEventListener('wheel', () => {
      const activeElement: any = document.activeElement
      if (activeElement?.type === 'number') {
        activeElement?.blur()
      }
    })
  })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        // suspense: true,
        cacheTime: 1000 * 60 * 60, // 1 hour
      },
      mutations: {},
    },
  })
  enableSplashScreen()
  setTimeout(() => {
    disableSplashScreen()
  }, 1500)

  return (
    <BrowserRouter>
      <MantineProvider
        withNormalizeCSS
        withCSSVariables
        theme={{
          fontFamily: 'Proxima Nova, sans-serif',
          spacing: {
            xl: rem(32),
          },
          components: {
            Container: {
              defaultProps: {
                sizes: {
                  xs: 540,
                  sm: 720,
                  md: 960,
                  lg: 1140,
                  xl: 1320,
                },
              },
            },
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <NotificationProvider>
              <Routes />
            </NotificationProvider>
          </AuthProvider>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </MantineProvider>
    </BrowserRouter>
  )
}

export { App }
