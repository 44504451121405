import * as React from 'react'
import { useEffect } from 'react'
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom'
import { useUserRole } from '@ospace/auth'
import { calculateDuration, Duration } from '@ospace/common'
import { Client } from '@ospace/schemas'
import { DurationPicker } from '@ospace/shared'
import { useDashboard } from '@ospace/shared/hooks/useDashboard'

import { useDataDogEmailTracker } from '../../datadog'

import { ClientPageLayout } from './ClientPageLayout'
import { PageId } from './SideNavbar'

type NavLayoutContextType = {
  client: Client
  user: any
  role: any
  duration: Duration
  setDuration: any
  dashboardStatus: string
  setLayoutConfig: any
}

const PAGES_WITH_DURATION_PICKER = ['dashboard', 'sales', 'leads', 'pipeline']

export const NavLayout = () => {
  const params: any = useParams()
  const clientId = Number(params?.clientId)
  const { duration, client, setDuration, user, status: dashboardStatus } = useDashboard(clientId)
  const role = useUserRole()
  const [{ pageId, pageTitle }, setLayoutConfig] = React.useState({
    pageId: 'dashboard' as PageId,
    pageTitle: 'Home',
  })

  const [showDurationPicker, setShowDurationPicker] = React.useState(true)

  useEffect(() => {
    setShowDurationPicker(PAGES_WITH_DURATION_PICKER.includes(pageId))
  }, [pageId])

  useDataDogEmailTracker()

  if (dashboardStatus === 'error') {
    return <Navigate to='/clients' />
  }
  if (dashboardStatus === 'loading' || !client) {
    return (
      <ClientPageLayout
        status='loading'
        pageId={pageId}
        pageTitle={pageTitle}
        user={user}
        role={role}
        {...(showDurationPicker && {
          subHeader: (
            <DurationPicker duration={calculateDuration('FY', 6)} setDuration={() => {}} />
          ),
        })}
      />
    )
  }
  return (
    <ClientPageLayout
      status='ready'
      fluid={true}
      client={client}
      user={user}
      role={role}
      {...(showDurationPicker && {
        subHeader: <DurationPicker duration={duration} setDuration={setDuration} />,
      })}
      pageId={pageId}
      pageTitle={pageTitle}
    >
      <Outlet
        context={{
          client,
          user,
          role,
          duration,
          setDuration,
          dashboardStatus,
          setLayoutConfig,
        }}
      />
    </ClientPageLayout>
  )
}
export function useNavLayout() {
  return useOutletContext<NavLayoutContextType>()
}
