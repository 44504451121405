import * as React from 'react'
import { userTypes } from '@ospace/core-auth'
import { FullPageLayout } from '@ospace/layout'
import { useUser } from '@ospace/user'

// LegacyFullPageLayoutContainer is for wrapping legacy pages without any
// reference to a client or campaign. It is usually used for Admin pages, in
// which case a role of role={userTypes.Admin} should be passed.
// If `role` is blank, it is assumed to be `userTypes.ClientUser`, which will
// hide any admin links.
export const LegacyFullPageLayoutContainer = (props: {
  children: React.ReactElement
  role?: userTypes
}) => {
  const user = useUser()

  return user.status === 'success' ? (
    <FullPageLayout status='ready' user={user.data} role={props.role || userTypes.ClientUser}>
      {props.children}
    </FullPageLayout>
  ) : (
    <FullPageLayout status='loading' user={user.data} role={userTypes.Admin} />
  )
}
