import React from 'react'
import { Spinner } from 'react-bootstrap'
import { Campaigns, CampaignsHeader, useCampaigns } from '@ospace/campaign'
import { useClients } from '@ospace/client'

export const CampaignCards = () => {
  const clientsQuery = useClients({
    nextPage: 0,
    pageLimit: 2,
  })
  const campaignsQuery = useCampaigns()

  return (
    <>
      {clientsQuery.status === 'success' && campaignsQuery.status === 'success' ? (
        <>
          <div />
          {/* Hide header with client info if there are more than one clients*/}
          {clientsQuery.data.recordCount === 1 && (
            <CampaignsHeader campaigns={campaignsQuery.data} />
          )}
          <Campaigns campaigns={campaignsQuery.data} />
        </>
      ) : campaignsQuery.status === 'error' ? (
        <span>Error: {(campaignsQuery.error as any).message}</span>
      ) : clientsQuery.status === 'error' ? (
        <span>Error: {(clientsQuery.error as any).message}</span>
      ) : (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      )}
    </>
  )
}
