import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Center, Col, Flex, Grid, Loader } from '@mantine/core'
import { useCheckRole, useUserRole } from '@ospace/auth'
import {
  isClientsDistributionPipelineAvailableToUserRole,
  isClientsLeadsDashboardAvailableToUserRole,
  isClientsSalesDashboardAvailableToUserRole,
  userTypes,
} from '@ospace/core-auth'
import { useOpportunities } from '@ospace/distribution-pipeline'
import { useNavLayout } from '@ospace/layout'
import { useLeads } from '@ospace/leads-dashboard'
import { useSalesDashBoard } from '@ospace/sales-dashboard'
import { DEFAULT_CURRENCY, ErrorBoundary } from '@ospace/shared'

import { useDataDogEmailTracker } from '../../datadog'
import { GenerateMoreLeads, ScaleWithUsToday } from '../components/CallToActionCards'
import { LeadsDeactivatedCard, SalesDeactivatedCard } from '../components/DeactivatedCards'
import { LeadsCard } from '../components/LeadsCard'
import { PipelineCard } from '../components/PipelineCard'
import { SalesCard } from '../components/SalesCard'

type DashboardPageProps = { clientId: number }

export const DashboardPage: FC<DashboardPageProps> = ({ clientId }) => {
  const { client, duration, setLayoutConfig } = useNavLayout()

  useEffect(() => {
    setLayoutConfig({ pageId: 'dashboard', pageTitle: 'Home' })
  }, [setLayoutConfig])

  const role = useUserRole()

  const opportunitiesResp = useOpportunities({
    clientId,
    enabled: !!client?.distributionPipelineVendorId,
  })
  const salesResp = useSalesDashBoard(
    clientId,
    client?.salesDashboardVendorId as string,
    client?.JIWARegion as string
  )
  const leadsResp = useLeads({ clientId })

  useDataDogEmailTracker()

  const currency = client.currency || DEFAULT_CURRENCY

  //Show Leadsd board if the user has access to the leads dashboard and the leads dashboard is not in error state.
  //Pipeline and sales are always activated together, so even they have 2 switches they are treated as one. So if the user has access to the pipeline dashboard or the sales dashboard and neither of them are in error state
  //Show pipeline and sales dashbaord if the user has access to the pipeline dashboard or the sales dashboard and neither of them are in error state.
  const isLeadsDashboardActive =
    isClientsLeadsDashboardAvailableToUserRole({
      userRole: role,
      client,
    }) && !leadsResp.isError
  const isPipelineAndSalesDashboardActive =
    (isClientsDistributionPipelineAvailableToUserRole({
      userRole: role,
      client,
    }) ||
      isClientsSalesDashboardAvailableToUserRole({
        userRole: role,
        client,
      })) &&
    (!opportunitiesResp.isError || !salesResp.isError)

  return (
    <>
      {opportunitiesResp.isLoading || salesResp.isLoading || leadsResp.isLoading ? (
        <Center maw='100vh' mx='auto'>
          <Loader variant='bars' />
        </Center>
      ) : (
        <>
          <Grid>
            <Col xs={12} sm={isPipelineAndSalesDashboardActive ? 4 : 8} miw={360}>
              <Flex direction='column' justify='space-between' gap='md' h={'100%'}>
                {isLeadsDashboardActive ? (
                  <>
                    <LeadsCard leads={leadsResp.data || []} duration={duration} />
                    <GenerateMoreLeads />
                  </>
                ) : (
                  <LeadsDeactivatedCard />
                )}
              </Flex>
            </Col>
            {isPipelineAndSalesDashboardActive && (
              <Col xs={12} sm={4} miw={360}>
                <Flex direction='column' justify='space-between' gap='md' h={'100%'}>
                  <PipelineCard
                    opportunities={opportunitiesResp.data || []}
                    currency={currency}
                    duration={duration}
                  />
                </Flex>
              </Col>
            )}
            {isPipelineAndSalesDashboardActive && (
              <Col xs={12} sm={4} miw={360}>
                <Flex direction='column' justify='space-between' gap='md' h={'100%'}>
                  <SalesCard
                    sales={salesResp?.data || []}
                    opportunities={opportunitiesResp.data || []}
                    currency={client.currency || DEFAULT_CURRENCY}
                    duration={duration}
                  />
                  <ScaleWithUsToday />
                </Flex>
              </Col>
            )}
            {!isPipelineAndSalesDashboardActive && (
              <Col xs={12} sm={4}>
                <Flex direction='column' justify='space-between' gap='md' h={'100%'}>
                  <SalesDeactivatedCard />
                </Flex>
              </Col>
            )}
          </Grid>
        </>
      )}
    </>
  )
}

const Controller = () => {
  const params: any = useParams()
  const clientId = Number(params?.clientId)
  useCheckRole([
    userTypes.Admin,
    userTypes.ClientManager,
    userTypes.ClientViewer,
    userTypes.ClientUser,
    userTypes.InternalViewer,
    userTypes.CampaignManager,
  ])

  return <DashboardPage clientId={clientId} />
}

export const DashboardPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)

export default DashboardPageRouteHandler
